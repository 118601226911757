import englishFlag from './englishFlag.svg';
import dutchFlag from './dutchFlag.svg';
import germanFlag from './germanFlag.svg';
import frenchFlag from './frenchFlag.svg';

export const DutchFlag = () => (
  <div>
    <img src={dutchFlag} alt="Dutch flag" width='24' style={{ marginTop: 7 }} />
  </div>
);
export const EnglishFlag = () => (
  <div>
    <img src={englishFlag} alt="English flag" width='24' style={{ marginTop: 7 }} />
  </div>
);

export const GermanFlag = () => (
  <div>
    <img src={germanFlag} alt="German flag" width='24' style={{ marginTop: 7 }}/>
  </div>
);

export const FrenchFlag = () => (
  <div>
    <img src={frenchFlag} alt="French flag" width='24' style={{ marginTop: 7 }}/>
  </div>
);

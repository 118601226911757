import { useEffect, useState } from 'react';
import { Form, ResourceContextProvider, useNotify } from 'react-admin';
import { FieldValues } from 'react-hook-form';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { typedHttpClient } from '../../../utils/httpClient';
import { ConfigType, DefinitionType } from '../../../lib/constants/typedApiReturnTypes';
import { ClientConfigFormGenerator } from '../../../lib/clientConfigFormGenerator';
import authProvider from '../../../utils/authProvider';

export const KioskDefaults = () => {

  const [definition, setDefinition] = useState<DefinitionType | null>(null);
  const [config, setConfig] = useState<ConfigType | undefined>();
  const [updateTimestamp, setUpdateTimestamp] = useState<number>(0);
  const notify = useNotify();
  const navigate = useNavigate();
  const isDevAdmin = authProvider.isDeveloperAdmin();

  useEffect(() => {

    if (!isDevAdmin) {

      notify('resources.kiosks.text.no_access');
      navigate(-1);

    }

  }, [isDevAdmin]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    typedHttpClient().clientConfig.clientConfigControllerResolveConfigByType3().then((response) => {

      setDefinition(response.data.definition);
      setConfig(response.data.config);

    });

  }, [updateTimestamp]);

  const updateConfig = async (values: FieldValues) => {

    if (!definition) {

      return;

    }
    const updatePromises = [];

    for (const def of definition) {

      const originalValue = _.get(config, def.key);
      const newValue = _.get(values, def.key);

      if (!_.isEqual(originalValue, newValue)) {

        const id = def.mergeHistory[def.mergeHistory.length - 1].sources[0]._id;
        console.log('Updating', def.key, 'from', originalValue, 'to', newValue);
        const updatePromise = typedHttpClient().clientConfig.clientConfigControllerUpdateKey(id, { defaultValue: newValue });
        updatePromises.push(updatePromise);

      }

    }

    const results = await Promise.all(updatePromises);
    const hasError = results.some((result) => result.status >= 400);
    if (hasError) {

      notify('resources.kiosks.text.defaults_update_error', { type: 'error' });

    } else {

      notify('resources.kiosks.text.defaults_updated');

    }
    setUpdateTimestamp(Date.now());

  };

  return (
    <div>
      <h1>KioskConfig</h1>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <ResourceContextProvider value={'ClientConfig'}>
        <Form reValidateMode={'onChange'} record={config} mode="onBlur" onSubmit={updateConfig}>
          {definition && <ClientConfigFormGenerator definitions={definition} />}
        </Form>
      </ResourceContextProvider>
    </div>
  );

};

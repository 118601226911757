import { Datagrid, IfCanAccess, List } from '@react-admin/ra-rbac';
import {
  ArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  DateTimeInput,
  EditButton,
  FormDataConsumer,
  Labeled,
  RadioButtonGroupInput,
  ReferenceArrayInput,
  ReferenceInput,
  regex,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  Title,
  useInput,
  useRecordContext,
  useRedirect,
  useTranslate,
} from 'react-admin';
import * as React from 'react';
import { AnyReportTriggerChannel, ReportTriggerChannelType } from '@x-guard/xgac-types/xgac';
import {
  Autocomplete,
  Chip,
  FormControl, FormControlLabel, Radio, RadioGroup, TextField, Typography,
} from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';
import { JsonField, JsonInput } from 'react-admin-json-view';
import { useFormContext } from 'react-hook-form';
import moment from 'moment-timezone';
import { useGetList } from 'ra-core';
import { StabilityAlert, StabilityLevel } from '../../../components/StabilityAlert';
import SearchFields, { FilterResetter } from '../components/fields/Filters';
import { exporter } from '../../../utils/customExporter';
import { LIST_DEBOUNCE } from '../../../config';
import CustomBulkActionButtons from '../../../components/buttons/CustomBulkActionButtons';
import DateFieldNullable from '../../../components/fields/DateFieldNullable';
import { EditWithSuccess } from '../../../components/baseForms/EditWithSuccess';
import { CreateWithSuccess } from '../../../components/baseForms/CreateWithSuccess';
import {
  alarmSeverities, alarmTypes, appModeOptions, timeGranularityTypeForFlooring,
} from '../../../lib/constants/selectChoices';
import { TimeGranularityInput } from '../../../components/inputs/timeGranularityInput';
import { localeDefaults } from '../../../lib/constants/localesOptions';
import authProvider from '../../../utils/authProvider';
import { simpleReportTransformer } from '../../../utils/reportDefaults';
import { BooleanSetterField } from '../../../components/fields/BooleanSetterField';
import { MultipleChipInput } from '../../../components/inputs/MultipleChipInput';
import { ChannelField } from '../components/fields/ReportChannelField';
import { TranslatedField } from '../../../components/fields/TranslatedField';
import { RunIntervalField } from '../../../components/fields/RunIntervalField';
import { emailArrayValidation } from '../../../utils/emailArrayValidation';
import { ReportCardList } from '../../../components/reports';
import { DateFieldWithTime } from '../../../components/fields/DateFieldWithTime';
import { HasRoles } from '../../../components/HasRoles';
import { AuditlogButton } from '../components/buttons/AuditlogButton';

const themeOptions = [
  { id: 'default', name: 'resources.report-triggers.text.theme.default' },
  { id: 'bhvk', name: 'resources.report-triggers.text.theme.bhvk' },
];
const transform = (data: Record<string, any>) => {

  delete data.sendForCurrentMonth;
  delete data.otherType;
  delete data.type;
  if (data.config.type === 'newAlarm') {

    data.scheduleTrigger = null;

    data.config = {
      ...data.config,
      filter: {
        ...data.config?.filter,
        assets: data.config?.filter?.assets?.map((asset: string) => {

          return {
            _id: asset,
            _ref: 'Asset',
          };

        }),
        assetGroups: data.config?.filter?.assetGroups?.map((assetGroup: string) => {

          return {
            _id: assetGroup,
            _ref: 'AssetGroup',
          };

        }),
      },

    };

  } else {

    data.scheduleTrigger = {
      ...data.scheduleTrigger,
      nextAt: data.scheduleTrigger?.nextAt ? new Date(data.scheduleTrigger.nextAt).toISOString() : null,
      lastAt: data.scheduleTrigger?.lastAt || null,
      lastAttemptedAt: data.scheduleTrigger?.lastAttemptedAt || null,
    };

  }
  if (data.config.type === 'inactivityMonitor') {

    if (data.config.assets.length === 0) {

      data.config.assets = undefined;

    }
    if (data.config.assetGroups.length === 0) {

      data.config.assetGroups = undefined;

    }

  }
  if (data.config.type === 'appUsage') {

    data.config.filter = {
      ...data.config?.filter,
      assets: data.config?.filter?.assets?.map((asset: string) => {

        return {
          _id: asset,
          _ref: 'Asset',
        };

      }),
      assetGroups: data.config?.filter?.assetGroups?.map((assetGroup: string) => {

        return {
          _id: assetGroup,
          _ref: 'AssetGroup',
        };

      }),
    };
    data.config.timeSeries.timeframe = {
      floorTo: data.config?.timeSeries?.timeframe?.floorTo || 'hour',
      type: 'month',
      value: 1,
    };

  }

  if (!data.channelOverrides?.unsubscribedEmails || data.channelOverrides?.unsubscribedEmails.length === 0) {

    delete data.channelOverrides;

  }
  return {
    ...data,
    ...(data.config && {
      config: {
        ...data.config,
        ...(data.config?.assets && {
          assets: data.config?.assets?.map((asset: string) => {

            return {
              _id: asset,
              _ref: 'Asset',
            };

          }),
        }),
        ...(data.config?.asset && {
          asset: {
            _id: data.config?.asset,
            _ref: 'Asset',
          },
        }),
        ...(data.config?.assetGroups && {
          assetGroups: data.config?.assetGroups?.map((assetGroup: string) => {

            return {
              _id: assetGroup,
              _ref: 'AssetGroup',
            };

          }),
        }),
        ...(data.config?.exclude && {
          exclude: data.config?.exclude?.map((exclude: string) => {

            return {
              _id: exclude,
              _ref: 'Customer',
            };

          }),
        }),
      },
    }),
    channels: data.channels.map((channel: any): AnyReportTriggerChannel => {

      if (channel.type === 'email') {

        return {
          ...channel,
          getters: [],
          type: ReportTriggerChannelType.Email,
          address: channel.address || [],
          contactLists: channel.contactLists?.map((contactList: string) => {

            return {
              _id: contactList,
              _ref: 'ContactList',
            };

          }) || [],

        };

      } if (channel.type === 'sms') {

        return {
          type: ReportTriggerChannelType.Sms,
          numbers: channel.numbers?.map((input: { sms: string }) => input.sms),
          contactLists: channel.contactLists?.map((contactList: string) => {

            return {
              _id: contactList,
              _ref: 'ContactList',
            };

          }),

        };

      }
      if (channel.type === 'alarm') {

        delete channel.address;

      }
      return channel;

    }),

  };

};

const twoStepTransform = async (data: Record<string, any>) => {

  const reportTheme = data.config?.theme;
  const transformResult = transform(await simpleReportTransformer(data));
  transformResult.config.theme = reportTheme;
  return transformResult;

};
const configTypes = (includeHistory = true) => {

  return [
    { name: 'resources.report-triggers.text.config_types.unhealthyBeacons', id: 'unhealthyBeacons' },
    { name: 'resources.report-triggers.text.config_types.alarms', id: 'alarms' },
    { name: 'resources.report-triggers.text.config_types.alarmCenterStatus', id: 'alarmCenterStatus' },
    { name: 'resources.report-triggers.text.config_types.newAlarm', id: 'newAlarm' },
    { name: 'resources.report-triggers.text.config_types.inactivityMonitor', id: 'inactivityMonitor' },
    { name: 'resources.report-triggers.text.config_types.appUsage', id: 'appUsage' },
    ...(includeHistory ? [{ name: 'resources.report-triggers.text.config_types.assetHistory', id: 'assetHistory' }] : []),
  ];

};

const channelsPerConfigType: any = {
  unhealthyBeacons: [
    { name: 'resources.assets.fields.email', id: 'email' },
  ],
  alarms: [
    { name: 'resources.assets.fields.email', id: 'email' },
  ],
  alarmCenterStatus: [
    { name: 'resources.assets.fields.email', id: 'email' },
  ],
  newAlarm: [
    { name: 'resources.assets.fields.email', id: 'email' },
  ],
  appUsage: [
    { name: 'resources.assets.fields.email', id: 'email' },
  ],
  assetHistory: [
    { name: 'resources.assets.fields.email', id: 'email' },
  ],
  inactivityMonitor: [
    { name: 'resources.assets.fields.email', id: 'email' },
    { name: 'general.text.alarm', id: 'alarm' },
  ],

};

const excelFormats = [
  { name: 'resources.report-triggers.text.config.excel_types.default', id: 'default' },
  { name: 'resources.report-triggers.text.config.excel_types.smc', id: 'smc' },
];

const appUsageLicenceModes = [
  { name: 'resources.report-triggers.text.licence_modes.hourlyUsage', id: 'hourlyUsage' },
  { name: 'resources.report-triggers.text.licence_modes.license', id: 'license' },
];

const appUsageFilterTags = [
  { name: 'resources.report-triggers.text.filter_tags.active', id: 'active' },
  { name: 'resources.report-triggers.text.filter_tags.inactive', id: 'inactive' },
  { name: 'resources.report-triggers.text.filter_tags.new', id: 'new' },
  { name: 'resources.report-triggers.text.filter_tags.forgotten', id: 'forgotten' },
];

const eventTriggerEvents = [
  { name: 'Alarm.create', id: 'Alarm.create' },
];
const UnsubscribeEmailsInput = () => {

  const { field, fieldState } = useInput(
    { source: 'channelOverrides.unsubscribedEmails', validate: [emailArrayValidation], shouldUnregister: true },
  );
  const translate = useTranslate();
  const form = useFormContext();

  const handleChange = (event: SyntheticEvent, value: string[]) => {

    event.preventDefault();
    field.onChange(value);

  };

  const hasEmail = form.getValues('channels')?.find((channel: any) => channel.type === 'email' || channel.type === 'contactList');

  if (!hasEmail) {

    return <></>;

  }
  return (
    <Autocomplete
      multiple
      id="tags-filled"
      options={[]}
      fullWidth={true}
      handleHomeEndKeys
      defaultValue={field.value || []}
      freeSolo
      autoSelect
      onChange={handleChange}
      renderTags={(renderValue: readonly string[], getTagProps) => renderValue.map((option: string, index: number) => (
        <Chip variant="filled" size="small" label={option} {...getTagProps({ index })} />
      ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
          fullWidth={true}
          error={fieldState.invalid}
          helperText={translate('resources.report-triggers.text.unsubscribedEmails_helper')}
          label={translate('resources.report-triggers.fields.unsubscribedEmails')}
        />
      )}
    />);

};
const ConfigInput = () => {

  return (
    <>
      <FormDataConsumer>
        {({ formData }) => {

          if (formData?.config?.type === 'unhealthyBeacons') {

            return (
              <TimeGranularityInput source="config.lastSeenThreshold" label="resources.report-triggers.fields.config.last_seen_threshold" shouldUnregister/>
            );

          } if (formData?.config?.type === 'alarms') {

            return (
              <>
                <SelectInput source="config.excelFormat" choices={excelFormats} validate={required()} defaultValue='default' shouldUnregister/>
                <SelectArrayInput source="config.alarmTypes" choices={alarmTypes} shouldUnregister/>
                <SelectArrayInput source="config.severities" choices={alarmSeverities} shouldUnregister/>
                <Labeled label="resources.report-triggers.fields.config.timeSeries_title" sx={{ marginLeft: '20px' }}>
                  <>
                    <TimeGranularityInput
                      source="config.timeSeries.granularity"
                      label="resources.report-triggers.fields.config.timeSeries.granularity"
                      shouldUnregister
                    />
                    <TimeGranularityInput
                      source="config.timeSeries.timeframe"
                      label="resources.report-triggers.fields.config.timeSeries.timeframe"
                      shouldUnregister
                    />
                  </>
                </Labeled>
                <Labeled label="general.text.filter">
                  <>
                    <ReferenceArrayInput source="config.filter.assets" reference="all-assets">
                      <AutocompleteArrayInput label="menu.items.assets" shouldUnregister/>
                    </ReferenceArrayInput>
                    <ReferenceArrayInput source="config.filter.assetGroups" reference="asset-groups">
                      <AutocompleteArrayInput label="resources.asset-groups.text.title" shouldUnregister/>
                    </ReferenceArrayInput>
                  </>
                </Labeled>
              </>
            );

          } if (formData?.config?.type === 'newAlarm') {

            return (
              <>
                <SelectArrayInput source="config.filter.alarmTypes" label="resources.report-triggers.fields.config.alarmTypes"
                  choices={alarmTypes} shouldUnregister/>
                <SelectArrayInput source="config.filter.severities" choices={alarmSeverities} shouldUnregister
                  label="resources.report-triggers.fields.config.severities"
                />
                <ReferenceArrayInput source="config.filter.assets" reference="all-assets">
                  <AutocompleteArrayInput label="Assets" shouldUnregister/>
                </ReferenceArrayInput>
                <ReferenceArrayInput source="config.filter.assetGroups" reference="asset-groups">
                  <AutocompleteArrayInput label="Asset Groups" shouldUnregister/>
                </ReferenceArrayInput>
                <TextInput source="config.procedure" shouldUnregister label="resources.alarms.fields.procedure" multiline/>
                <TextInput source="config.info" shouldUnregister label="info" multiline/>
                <SelectArrayInput source="eventTrigger.events" defaultValue={['Alarm.create']} choices={eventTriggerEvents} disabled shouldUnregister
                  label="Events"/>
              </>
            );

          }
          if (formData?.config?.type === 'inactivityMonitor') {

            return (
              <>
                <TimeGranularityInput source="config.lastSeenThreshold" label="resources.report-triggers.fields.config.last_seen_threshold" shouldUnregister/>
                <ReferenceArrayInput source="config.assets" reference="all-assets">
                  <AutocompleteArrayInput label="Assets" shouldUnregister/>
                </ReferenceArrayInput>
                <ReferenceArrayInput source="config.assetGroups" reference="asset-groups">
                  <AutocompleteArrayInput label="Asset Groups" shouldUnregister/>
                </ReferenceArrayInput>
                <SelectArrayInput source="config.appMode" label="resources.report-triggers.fields.config.app_mode" choices={appModeOptions} shouldUnregister/>
                <TimeGranularityInput source="config.timeout" label="resources.report-triggers.fields.config.timeout" shouldUnregister validation={[]}/>
              </>
            );

          }
          if (formData?.config?.type === 'appUsage') {

            return (
              <>
                <SelectInput source="config.mode" validate={required()} choices={appUsageLicenceModes} shouldUnregister/>
                <Labeled label="resources.report-triggers.fields.config.timeSeries_title" sx={{ marginLeft: '20px' }}>
                  <>
                    <TimeGranularityInput
                      source="config.timeSeries.granularity"
                      label="resources.report-triggers.fields.config.timeSeries.granularity"
                      shouldUnregister
                      defaultNumberValue={1}
                      defaultValue="hour"
                      disabled={true}
                    />
                    <SelectInput source={'config.timeSeries.timeframe.floorTo'} choices={timeGranularityTypeForFlooring}
                      defaultValue="month"
                      label="resources.report-triggers.fields.config.floorTo"
                      shouldUnregister
                      validate={required()}
                    />
                  </>
                </Labeled>
                <Labeled label="general.text.filter">
                  <>
                    <ReferenceArrayInput source="config.filter.assets" reference="all-assets">
                      <AutocompleteArrayInput label="menu.items.assets" shouldUnregister/>
                    </ReferenceArrayInput>
                    <ReferenceArrayInput source="config.filter.assetGroups" reference="asset-groups">
                      <AutocompleteArrayInput label="resources.asset-groups.text.title" shouldUnregister/>
                    </ReferenceArrayInput>
                  </>
                </Labeled>
                <SelectArrayInput source="config.filterTags" label="Filter Tags" choices={appUsageFilterTags} shouldUnregister/>
              </>
            );

          }

          if (formData?.config?.type === 'assetHistory') {

            return (
              <>
                <ReferenceInput source="config.asset" reference="all-assets">
                  <AutocompleteInput shouldUnregister validate={required()} label="menu.items.assets"/>
                </ReferenceInput>
                <Labeled label="resources.report-triggers.fields.config.timeSeries_title" sx={{ marginLeft: '20px' }}>
                  <>
                    <TimeGranularityInput
                      source="config.timeSeries.granularity"
                      label="resources.report-triggers.fields.config.timeSeries.granularity"
                      shouldUnregister
                    />
                    <TimeGranularityInput
                      source="config.timeSeries.timeframe"
                      label="resources.report-triggers.fields.config.timeSeries.timeframe"
                      shouldUnregister
                    />
                  </>
                </Labeled>
                <BooleanInput source="config.aggregatorOptions.includeRaw" defaultValue={false} shouldUnregister
                  label="resources.report-triggers.fields.config.agOptions.includeRaw"/>
                <BooleanInput source="config.aggregatorOptions.includeSimple" defaultValue={false} shouldUnregister
                  label="resources.report-triggers.fields.config.agOptions.includeSimple"/>
              </>
            );

          }
          if (formData?.config?.type === 'alarmCenterStatus') {

            return (
              <>
                <ReferenceArrayInput source="config.exclude" reference="customers" shouldUnregister
                  label="resources.report-triggers.fields.config.excludeCustomers">
                  <AutocompleteArrayInput shouldUnregister/>
                </ReferenceArrayInput>
                <TimeGranularityInput source="config.timeframe" shouldUnregister/>
              </>
            );

          }

          return <></>;

        }}
      </FormDataConsumer>
    </>
  );

};

const ChannelTypeInput = () => {

  const translate = useTranslate();
  const [emailRadioValue, setEmailRadioValue] = useState('email');
  const [hasChanged, setHasChanged] = useState(false);

  const { watch } = useFormContext();

  const invalidValues = watch('channelOverrides.unsubscribedEmails') || [];

  return (
    <>
      <FormDataConsumer>
        {({ scopedFormData }) => {

          const currentType = scopedFormData?.type;

          if (!currentType || currentType === 'email') {

            if (scopedFormData?.contactLists?.length > 0 && !hasChanged) {

              setEmailRadioValue('contactList');

            }
            setHasChanged(true);

            return (
              <>
                <FormControl>
                  <RadioGroup
                    row
                    defaultValue="email"
                    value={emailRadioValue}
                    onChange={(e) => setEmailRadioValue(e.target.value)}
                  >
                    <FormControlLabel value="email" control={<Radio />} label="Email" />
                    <FormControlLabel value="contactList" control={<Radio />} label={translate('resources.report-triggers.text.channel_types.contact_list')} />
                  </RadioGroup>
                </FormControl>
                {emailRadioValue === 'email' && (
                  <MultipleChipInput source={'address'}
                    label="resources.report-triggers.fields.channels_address"
                    validate={[emailArrayValidation, required()]}
                    invalidValues={invalidValues}
                  />
                )}
                {emailRadioValue === 'contactList' && (
                  <ReferenceArrayInput source={'contactLists'} reference="contact-lists" shouldUnregister>
                    <AutocompleteArrayInput label="resources.report-triggers.text.channel_types.contact_list" shouldUnregister validate={required()}/>
                  </ReferenceArrayInput>
                )}
              </>
            );

          }
          if (currentType === 'sms') {

            return (
              <>
                <ArrayInput source={'numbers'}
                  label="resources.report-triggers.fields.channels_number"
                  shouldUnregister>
                  <SimpleFormIterator disableReordering>
                    <TextInput source="sms" label="resources.assets.fields.smsNumber"
                      validate={regex(/^\+[0-9]{8,}$/, 'resources.users.text.invalid_sms')}
                      shouldUnregister/>
                  </SimpleFormIterator>
                </ArrayInput>
                <ReferenceArrayInput source={'contactLists'} reference="contact-lists">
                  <AutocompleteArrayInput label="resources.report-triggers.text.channel_types.contact_list" shouldUnregister/>
                </ReferenceArrayInput>
              </>
            );

          }
          if (currentType === 'alarm') {

            return (
              <>
                <TextInput source={'config.name'} validate={required()} shouldUnregister label="general.fields.name"/>
                <AutocompleteInput source={'config.type'} validate={required()} choices={alarmTypes} shouldUnregister
                  label="resources.alarms.fields.type"/>
                <AutocompleteInput source={'config.severity'} validate={required()} choices={alarmSeverities} shouldUnregister
                  label="resources.alarms.fields.severity"/>
                <TextInput multiline source={'config.procedure'} shouldUnregister
                  label="resources.alarms.fields.procedure"/>
              </>
            );

          }

          return <></>;

        }}
      </FormDataConsumer>
    </>);

};
const CustomerAdminReportTriggerList = () => {

  // const possibleReportTypes = ['appUsage', 'alarms', 'unhealthyBeacons', 'newAlarm'];
  const hasBeacons = useGetList(
    'beacons',
    {
      pagination: {
        page: 1, perPage: 1,
      },
      sort: {
        field: 'createdAt',
        order: 'DESC',
      },
    },
  );
  const possibleReportTypes = (hasBeacons?.total && hasBeacons?.total > 0)
    ? ['appUsage', 'alarms', 'unhealthyBeacons', 'newAlarm']
    : ['appUsage', 'alarms', 'newAlarm'];
  const translate = useTranslate();

  const [lastSaved, setLastSaved] = useState<Date | false | 'saving' | null>(null);
  return (
    <div>
      <Title title="resources.reports.title"/>
      <Typography variant={'h4'}>{translate('resources.reports.title')}
        <div style={{ marginLeft: '15px', display: 'inline' }}>
          {lastSaved === false && (
            <Chip size="small" label={translate('general.text.cannot_save')} color="error"/>
          )}
          {lastSaved === 'saving' && (
            <Chip size="small" label={translate('general.text.saving')} color="warning" sx={{ color: 'white' }}/>
          )}
          {lastSaved === null && (
            <Chip size="small" label={translate('general.text.no_changes')}/>
          )}
          {(lastSaved && lastSaved !== 'saving') && (
            <Chip
              size="small"
              label={`${translate('general.text.saved')}: ${moment(lastSaved).format('HH:mm:ss')}`}
              color="success"/>
          )}
        </div>
      </Typography>
      <ReportCardList possibleReportTypes={possibleReportTypes} setLastSaved={setLastSaved}/>
    </div>
  );

};
export const ReportTriggerList = () => {

  const isCustomerAdmin = authProvider.isCustomerAdmin();
  if (isCustomerAdmin) {

    return <CustomerAdminReportTriggerList/>;

  }
  return (<>
    <StabilityAlert stability={StabilityLevel.Stable}/>
    <List filters={SearchFields} exporter={exporter} debounce={LIST_DEBOUNCE}
      title="resources.report-triggers.text.title">
      <>
        <FilterResetter/>
        <Datagrid rowClick={false} bulkActionButtons={<CustomBulkActionButtons/>}>
          <BooleanSetterField source={'enabled'} label="resources.report-triggers.fields.enabled" />
          <TranslatedField source="config.type" preValue="resources.report-triggers.text.config_types."/>
          <RunIntervalField />
          <ChannelField source="channels" />
          <DateFieldNullable source="scheduleTrigger.lastAttemptedAt" label="resources.report-triggers.fields.lastAttemptedAt" showTime={true}/>
          <DateFieldNullable source="scheduleTrigger.lastAt" label="resources.report-triggers.fields.lastRunAt" showTime={true}/>
          <DateFieldNullable source="scheduleTrigger.nextAt" label="resources.report-triggers.fields.nextRunAt" showTime={true}/>
          <DateFieldWithTime source="createdAt" label="general.fields.createdAt" timeOnHover={true}/>
          <IfCanAccess action="edit">
            <EditButton/>
          </IfCanAccess>
          <HasRoles anyOf={['developer_admin']}>
            <AuditlogButton/>
          </HasRoles>
        </Datagrid>
      </>
    </List>
  </>);

};

const ConfigRoleCheck = () => {

  const isDevAdmin = authProvider.isDeveloperAdmin();
  const record = useRecordContext();
  const redirect = useRedirect();

  useEffect(() => {

    if (record?.config?.type === 'appUsage' && !isDevAdmin) {

      redirect('list', 'report-triggers');

    }

  }, [record?.config?.type, isDevAdmin]); // eslint-disable-line react-hooks/exhaustive-deps
  return <></>;

};

const ReportEditFields = () => {

  const record = useRecordContext();
  if (!record) {

    return <></>;

  }

  if (configTypes()?.find((type) => record.config?.type === type.id)) {

    return (
      <>
        <ConfigRoleCheck/>
        <SelectInput source="config.type" choices={configTypes()} validate={required()} InputProps={{ disabled: true }}/>
        <ConfigInput/>
        <ArrayInput source="channels" shouldUnregister>
          <SimpleFormIterator disableReordering disableClear>
            <FormDataConsumer>
              {({ formData }) => {

                return (
                  <RadioButtonGroupInput source="type" defaultValue={channelsPerConfigType[formData.config.type]?.[0]?.id}
                    label="resources.report-triggers.fields.channels_type"
                    choices={channelsPerConfigType[formData.config.type]} fullWidth/>
                );

              }}
            </FormDataConsumer>
            <ChannelTypeInput />
          </SimpleFormIterator>
        </ArrayInput>
        <MultipleChipInput
          source={'channelOverrides.unsubscribedEmails'}
          label={'resources.report-triggers.fields.unsubscribedEmails'}
          helperText={'resources.report-triggers.text.unsubscribedEmails_helper'}
        />
        <SelectInput source="locale" choices={localeDefaults}/>
        <FormDataConsumer>
          {({ formData }) => {

            if (formData?.config?.type !== 'newAlarm') {

              return (
                <>
                  <DateTimeInput source="scheduleTrigger.nextAt" shouldUnregister label="resources.report-triggers.fields.nextRunAt"/>
                  <TimeGranularityInput source="scheduleTrigger.interval" label="resources.report-triggers.fields.runInterval_title"/>
                </>);

            }
            return null;

          }}
        </FormDataConsumer>
        <SelectInput source={'config.theme'} choices={themeOptions} shouldUnregister label="general.text.theme" defaultValue="default" required/>

        <BooleanInput source="enabled"/>
      </>
    );

  }
  return (
    <>
      <JsonInput source="config" shouldUnregister
        reactJsonOptions={{
          // Props passed to react-json-view
          name: 'config',
          collapsed: false,
          enableClipboard: true,

        }}/>
      <JsonInput source="scheduleTrigger" shouldUnregister
        reactJsonOptions={{
          // Props passed to react-json-view
          name: 'scheduleTrigger',
          collapsed: false,
          enableClipboard: true,
        }}/>
      <JsonInput source="channels" shouldUnregister
        reactJsonOptions={{
          // Props passed to react-json-view
          name: 'channels',
          collapsed: false,
          enableClipboard: true,
        }}/>
      <JsonInput source="eventTrigger" shouldUnregister
        reactJsonOptions={{
          // Props passed to react-json-view
          name: 'eventTrigger',
          collapsed: false,
          enableClipboard: true,
        }}/>
      <SelectInput source={'config.theme'} choices={themeOptions} shouldUnregister label="general.text.theme" defaultValue="default" required/>

      <BooleanInput source="enabled"/>
    </>
  );

};

const SimpleReportConfigFields = () => {

  const translate = useTranslate();

  return <>
    <FormDataConsumer>
      {({ formData }) => {

        if (formData?.config?.type === 'newAlarm') {

          return (
            <>
              <Typography variant="h6" >
                {translate('resources.report_triggers.text.specific_fields')}
              </Typography>
              <SelectArrayInput source="config.filter.alarmTypes" label="resources.report-triggers.fields.config.alarmTypes"
                choices={alarmTypes} shouldUnregister/>
              <SelectArrayInput source="config.filter.severities" choices={alarmSeverities} shouldUnregister
                label="resources.report-triggers.fields.config.severities"
              />
              <ReferenceArrayInput source="config.filter.assets" reference="all-assets">
                <AutocompleteArrayInput label="Assets" shouldUnregister/>
              </ReferenceArrayInput>
              <ReferenceArrayInput source="config.filter.assetGroups" reference="asset-groups">
                <AutocompleteArrayInput label="Asset Groups" shouldUnregister/>
              </ReferenceArrayInput>
              <TextInput source="config.procedure" shouldUnregister label="resources.alarms.fields.procedure" multiline/>
              <TextInput source="config.info" shouldUnregister label="info" multiline/>
            </>
          );

        }
        if (formData?.config?.type === 'inactivityMonitor') {

          return (
            <>
              <Typography variant="h6" >
                {translate('resources.report_triggers.text.specific_fields')}
              </Typography>
              <TimeGranularityInput source="config.lastSeenThreshold" label="resources.report-triggers.fields.config.last_seen_threshold" shouldUnregister/>
              <ReferenceArrayInput source="config.assets" reference="all-assets">
                <AutocompleteArrayInput label="Assets" shouldUnregister/>
              </ReferenceArrayInput>
              <ReferenceArrayInput source="config.assetGroups" reference="asset-groups">
                <AutocompleteArrayInput label="Asset Groups" shouldUnregister/>
              </ReferenceArrayInput>
              <SelectArrayInput source="config.appMode" label="resources.report-triggers.fields.config.app_mode" choices={appModeOptions} shouldUnregister/>
              <TimeGranularityInput source="config.timeout" label="resources.report-triggers.fields.config.timeout" shouldUnregister validation={[]}/>
            </>
          );

        }

        if (formData?.config?.type === 'assetHistory') {

          return (
            <>
              <Typography variant="h6" >
                {translate('resources.report_triggers.text.specific_fields')}
              </Typography>
              <ReferenceInput source="config.asset" reference="all-assets">
                <AutocompleteInput shouldUnregister validate={required()} label="menu.items.assets"/>
              </ReferenceInput>
              <Labeled label="resources.report-triggers.fields.config.timeSeries_title" sx={{ marginLeft: '20px' }}>
                <>
                  <TimeGranularityInput
                    source="config.timeSeries.granularity"
                    label="resources.report-triggers.fields.config.timeSeries.granularity"
                    shouldUnregister
                  />
                  <TimeGranularityInput
                    source="config.timeSeries.timeframe"
                    label="resources.report-triggers.fields.config.timeSeries.timeframe"
                    shouldUnregister
                  />
                </>
              </Labeled>
              <BooleanInput source="config.aggregatorOptions.includeRaw" defaultValue={false} shouldUnregister
                label="resources.report-triggers.fields.config.agOptions.includeRaw"/>
              <BooleanInput source="config.aggregatorOptions.includeSimple" defaultValue={false} shouldUnregister
                label="resources.report-triggers.fields.config.agOptions.includeSimple"/>
            </>
          );

        }
        if (formData?.config?.type === 'appUsage') {

          const dayOfMonth = new Date().getDate();
          return (<>
            <Typography variant="h6" >
              {translate('resources.report_triggers.text.specific_fields')}
            </Typography>
            <SelectInput source="config.mode" validate={required()} choices={appUsageLicenceModes} shouldUnregister defaultValue="hourlyUsage"/>
            <BooleanInput
              source="sendForCurrentMonth"
              shouldUnregister
              disabled={dayOfMonth > 9} helperText={dayOfMonth > 9 ? 'resources.report-triggers.text.send-current-month-unavailable' : ''}
            />
          </>);

        }
        return <></>;

      }}
    </FormDataConsumer>
  </>;

};

const ChannelSwitcher = (props: { source: string }) => {

  const { field } = useInput({ source: props.source });
  const { getValues } = useFormContext();
  const formData = getValues();
  useEffect(() => {

    if (formData?.config?.type !== 'inactivityMonitor') {

      field.onChange('email');

    }

  }, [field, formData?.config?.type]);

  return <></>;

};

const SimpleReportFields = (props: { isEdit: boolean }) => {

  const translate = useTranslate();
  const record = useRecordContext();

  if (!props.isEdit || configTypes()?.find((type) => record?.config?.type === type.id)) {

    return (
      <>
        <ChannelSwitcher source={'channels.0.type'}/>
        <Typography variant="h6" gutterBottom>
          {translate('resources.report_triggers.what_report')}
        </Typography>
        <SelectInput source="config.type" choices={configTypes(false)} validate={required()} disabled={props.isEdit}
          defaultValue="unhealthyBeacons"/>
        <Typography variant="h6" gutterBottom>
          {translate('resources.report_triggers.text.to_whom')}
        </Typography>
        <ArrayInput source="channels" shouldUnregister defaultValue={[{ type: 'email' }]}>
          <SimpleFormIterator disableReordering disableClear disableAdd disableRemove>
            <FormDataConsumer>
              {({ formData }) => {

                if (channelsPerConfigType[formData.config.type]?.length === 1) {

                  return null;

                }

                return (
                  <RadioButtonGroupInput source={'type'} defaultValue="email"
                    label="resources.report-triggers.fields.channels_type"
                    choices={channelsPerConfigType[formData.config.type]} fullWidth/>
                );

              }}
            </FormDataConsumer>
            <ChannelTypeInput/>
          </SimpleFormIterator>
        </ArrayInput>
        <UnsubscribeEmailsInput/>
        <FormDataConsumer>
          {({ formData }) => {

            if (!['newAlarm', 'appUsage'].includes(formData?.config?.type)) {

              return (
                <>
                  <Typography variant="h6" gutterBottom>
                    {translate('general.text.how_often')}
                  </Typography>
                  <TimeGranularityInput source="scheduleTrigger.interval"
                    label="resources.report-triggers.fields.runInterval_title"
                    defaultValue="month"/>
                </>
              );

            }
            return <></>;

          }}
        </FormDataConsumer>
        <SimpleReportConfigFields/>
        <Typography variant="h6" gutterBottom>
          {translate('general.text.theme')}
        </Typography>
        <SelectInput source={'config.theme'} choices={themeOptions} shouldUnregister label="general.text.theme"
          defaultValue="default" required/>
      </>
    );

  }
  return (
    <>
      <JsonField source="config"
        reactJsonOptions={{
          // Props passed to react-json-view
          name: 'config',
          collapsed: false,
          enableClipboard: true,

        }}/>
      <JsonField source="scheduleTrigger"
        reactJsonOptions={{
          // Props passed to react-json-view
          name: 'scheduleTrigger',
          collapsed: false,
          enableClipboard: true,

        }}/>
      <JsonField source="channels"
        reactJsonOptions={{
          // Props passed to react-json-view
          name: 'channels',
          collapsed: false,
          enableClipboard: true,

        }}/>
    </>
  );

};

export const ReportTriggerEdit = () => {

  const isDevAdmin = authProvider.isDeveloperAdmin();
  if (isDevAdmin) {

    return (
      <>
        <StabilityAlert stability={StabilityLevel.Beta}/>
        <EditWithSuccess title="resources.report-triggers.text.title" transform={transform}>
          <SimpleForm>
            <ReportEditFields/>
          </SimpleForm>
        </EditWithSuccess>
      </>
    );

  }
  return (
    <>
      <EditWithSuccess title="resources.report-triggers.text.title" transform={twoStepTransform}>
        <SimpleForm>
          <SimpleReportFields isEdit={true}/>
        </SimpleForm>
      </EditWithSuccess>
    </>
  );

};

const ReportCreateFields = () => {

  return (
    <FormDataConsumer>
      {(consumerData) => {

        const typeFormData = consumerData.formData;
        if (!typeFormData.otherType) {

          return (
            <>
              <SelectInput source="config.type" choices={configTypes()} validate={required()}
                defaultValue={configTypes()?.[0].id}/>
              <ConfigInput/>

              <ArrayInput source="channels" defaultValue={[{ type: 'email', address: [] }]}>
                <SimpleFormIterator disableReordering disableClear>
                  <FormDataConsumer>
                    {({ formData }) => {

                      return (
                        <RadioButtonGroupInput source={'type'}
                          defaultValue={channelsPerConfigType[formData.config.type]?.[0]?.id}
                          label="resources.report-triggers.fields.channels_type"
                          choices={channelsPerConfigType[formData.config.type]} fullWidth/>
                      );

                    }}
                  </FormDataConsumer>
                  <ChannelTypeInput/>
                </SimpleFormIterator>
              </ArrayInput>
              <UnsubscribeEmailsInput/>
              <SelectInput source="locale" choices={localeDefaults}/>
              <FormDataConsumer>
                {(formDataConsumer) => {

                  const innerFormData = formDataConsumer.formData;
                  if (innerFormData?.config?.type !== 'newAlarm') {

                    return (
                      <>
                        <DateTimeInput source="scheduleTrigger.nextAt" shouldUnregister
                          label="resources.report-triggers.fields.nextRunAt"/>
                        <TimeGranularityInput source="scheduleTrigger.interval"
                          label="resources.report-triggers.fields.runInterval_title"/>
                      </>
                    );

                  }
                  return null;

                }}
              </FormDataConsumer>
              <SelectInput source={'config.theme'} choices={themeOptions} shouldUnregister label="general.text.theme" defaultValue="default" required/>
              <BooleanInput source="enabled"/>
            </>

          );

        }
        return (
          <>
            <JsonInput source="config" shouldUnregister
              reactJsonOptions={{
                // Props passed to react-json-view
                name: 'config',
                collapsed: false,
                enableClipboard: true,

              }}/>
            <JsonInput source="scheduleTrigger" shouldUnregister
              reactJsonOptions={{
                // Props passed to react-json-view
                name: 'scheduleTrigger',
                collapsed: false,
                enableClipboard: true,

              }}/>
            <JsonInput source="channels" shouldUnregister
              reactJsonOptions={{
                // Props passed to react-json-view
                name: 'channels',
                collapsed: false,
                enableClipboard: true,

              }}/>
          </>
        );

      }}
    </FormDataConsumer>
  );

};

export const ReportTriggerCreate = () => {

  const isDevAdmin = authProvider.isDeveloperAdmin();
  if (isDevAdmin) {

    return (
      <>
        <StabilityAlert stability={StabilityLevel.Beta}/>
        <CreateWithSuccess title="resources.report-triggers.text.title" transform={transform}>
          <SimpleForm>
            <BooleanInput source="otherType" defaultValue={false} label="Other (new) type" shouldUnregister
              fullWidth/>
            <ReportCreateFields/>
          </SimpleForm>
        </CreateWithSuccess>
      </>
    );

  }
  return (
    <>
      <CreateWithSuccess title="resources.report-triggers.text.title" transform={twoStepTransform}>
        <SimpleForm>
          <SimpleReportFields isEdit={false}/>
        </SimpleForm>
      </CreateWithSuccess>
    </>
  );

};

import {
  Datagrid,
  TextField,
  useRecordContext,
  DateField,
  useTranslate,
  Pagination,
  Button,
  useRedirect,
} from 'react-admin';
import * as React from 'react';
import { ListLive, ListLiveProps } from '@react-admin/ra-realtime';
import { useEffect, useState } from 'react';
import {
  TextFieldProps, Tooltip,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import PersonIcon from '@mui/icons-material/Person';
import { useListContext } from 'ra-core';
import TextFieldNullable from '../../../components/fields/TextFieldNullable';
import { httpClient } from '../../../utils/httpClient';
import { XGAC_MAIN_API_URL } from '../../../config';
import { RedirectToCreateAlarm } from '../components/redirectToCreateAlarm';
import { StyledButton } from '../buttons/StyledButton';

const apiUrl = XGAC_MAIN_API_URL;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AssetFieldClickable = (props: TextFieldProps) => {

  const record = useRecordContext();

  if (!record?.asset) return <TextFieldNullable/>;
  return <TextField source="asset.name" />;

};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const HelperListField = (props: any) => {

  const record = useRecordContext();
  const [helpers, setHelpers] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const redirect = useRedirect();
  const translate = useTranslate();
  const listContext = useListContext();

  useEffect(() => {

    if (loading) {

      return;

    }
    if (record?.id) {

      setLoading(true);
      httpClient(`${apiUrl}/alarms/${record.id}/alarm-helpers?$max=100`).then((res: any) => {

        if (res.json) {

          setHelpers(res.json.result.map((helper: any) => {

            return { name: helper.asset?.name || translate('resources.assets.text.bhvk_deleted'), confirmed: helper.confirmedHelping };

          }));
          setLoading(false);

        }

      });

    }

  }, [record, listContext]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!record?.id) return <TextFieldNullable/>;
  return (
    <div key={record.id}>
      {loading && <CircularProgress size="small"/>}
      {!loading && (helpers.length > 0 || record?.alarmCenter?.name) && (
        <div>
          {helpers.map((helper: { name: string; confirmed: boolean | null }) => (
            <Tooltip title={translate(helper.confirmed ? 'resources.alarms.text.will_help' : 'resources.alarms.text.will_not_help')} placement="top" arrow>
              <div key={helper.name} style={{ width: 'fit-content', color: helper.confirmed ? '#0F8F49' : '#f15a24' }}>
                <PersonIcon
                  style={{ verticalAlign: 'middle', position: 'relative', top: '-2px' }}
                /> {helper.name}
              </div>
            </Tooltip>))}
          {record?.alarmCenter?.name && (
            <Tooltip title={translate('resources.alarms.fields.alarmCenter.name')} placement="top" arrow>
              <div style={{ width: 'fit-content', color: '#0F8F49' }}>
                {record.alarmCenter.name}
              </div>
            </Tooltip>
          )}
        </div>
      )}
      {!loading && helpers.length === 0 && !record?.alarmCenter?.name && (
        <div className="flex-in-between">
          <Button
            onClick={() => redirect('/help/dashboard/waarom-wordt-er-niemand-opgeroepen')}
            size="small"
            style={{ textTransform: 'none', paddingLeft: 0 }}
            variant={'text'}
            color={'warning'}
            label="resources.alarms.text.nobody_notified"
          />
        </div>
      )}
    </div>
  );

};

export const AlarmListSmall = (props: {
  empty?: ListLiveProps['empty'];
  filter?: ListLiveProps['filter'];
  actions?: ListLiveProps['actions'];
  hasPagination?: boolean;
}) => {

  return (
    <>
      <ListLive
        resource={'alarms'}
        pagination={props.hasPagination ? <Pagination/> : false}
        perPage={50}
        actions={props.actions ? props.actions : false}
        sort={{ field: 'createdAt', order: 'DESC' }}
        empty={props.empty ? props.empty : <RedirectToCreateAlarm/>}
        filter={props.filter}
        emptyWhileLoading
        queryOptions={{
          meta: {
            $select: 'createdAt,customer,ack,severity,name,alarmCenter,asset',
          },
        }}
      >
        <Datagrid
          bulkActionButtons={false}>
          <DateField source="createdAt" showTime={true} label={'general.fields.createdAt'} />
          <TextField source="name" label={'general.fields.name'} sortable={false} />
          <AssetFieldClickable label={'general.fields.madeBy'}/>
          <HelperListField label="resources.alarms.text.notified"/>
        </Datagrid>
      </ListLive>
    </>
  );

};
export const AlarmList = () => {

  const redirect = useRedirect();
  const translate = useTranslate();

  const AlarmCreateButton = () => {

    return (
      <StyledButton onClick={() => {

        redirect('/maak-alarm');

      }} label={translate('menu.items.create_alarm')}/>
    );

  };

  return (
    <>
      <h3>{translate('menu.items.alarms')}</h3>
      <p>
        {translate('resources.alarms.text.title_subtext')}
      </p>
      <AlarmListSmall hasPagination={true} actions={<AlarmCreateButton/>} />
    </>
  );

};

import * as React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
// react admin
import {
  CustomRoutes, defaultTheme, resolveBrowserLocale,
} from 'react-admin';
import { Admin } from '@react-admin/ra-enterprise';
import { useEffect } from 'react';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import * as raEn from 'ra-language-english';
import * as raNl from 'ra-language-dutch';
import frenchMessages from 'ra-language-french';
import _ from 'lodash';
import {
  ThemeOptions, createTheme,
} from '@mui/material';
import { formalGermanMessages } from '@haleos/ra-language-german';
import { dataProvider } from '../../dataProviders';
import authProvider, { refreshContext } from '../../utils/authProvider';

import { Resource } from '../../components/Resource';

// Components
import { AssetGroupCreate, AssetGroupEdit, AssetGroupList } from './entities/assetGroup';
import { AssetCreate, AssetEdit, AssetList } from './entities/asset';
import { BeaconCreate, BeaconEdit, BeaconList } from './entities/beacon';

import { DeviceCreate, DeviceEdit, DeviceList } from './entities/device';
import {
  ObservationTriggerCreate,
  ObservationTriggerEdit,
  ObservationTriggerList,
} from './entities/observationTrigger';
import { OverlayMapList } from '../../components/overlay';
import { WebhookCreate, WebhookEdit, WebhookList } from './entities/webhook';
import {
  SimpleZoneMap, ZoneCreate, ZoneEdit, ZoneList,
} from './entities/zone';
import { UserCreate, UserEdit, UserList } from './entities/user';
import { StaticResponderCreate, StaticResponderEdit, StaticResponderList } from './entities/staticResponder';
import { UserSnap } from '../../lib/usersnap';
import { AppUserCreate, AppUserEdit, AppUserList } from './entities/appUser';
import { CustomerCreate, CustomerEdit, CustomerList } from './entities/customer';
import { HomePage } from './pages/homePage';
import { ServiceAccountCreate, ServiceAccountEdit, ServiceAccountList } from './entities/serviceAccount';
import { FlicHubCreate, FlicHubEdit, FlicHubList } from './entities/flicHub';
import { FlicButtonCreate, FlicButtonEdit, FlicButtonList } from './entities/flicButton';
import { AlarmCreate, AlarmList, OpenAlarmList } from './entities/alarm';
import { CustomerLinkCreate, CustomerLinkEdit, CustomerLinkList } from './entities/atsCustomerLink';
import { ProcessTemplateEdit, ProcessTemplateList } from './entities/atsProcessTemplate';
import { LiveMap } from '../../components/LiveMap';
import { UnhealthyBeaconReport } from './pages/unhealthyBeaconReport';
import { TemplateCreate, TemplateEdit, TemplateList } from './entities/qrCodeTemplate';
import { ContactListCreate, ContactListEdit, ContactListList } from './entities/contactList';
import { ReportTriggerCreate, ReportTriggerEdit, ReportTriggerList } from './entities/reportTrigger';
import { ReportTriggerRunList } from './entities/reportTriggerRun';
import { RoleCreate, RoleEdit, RoleList } from './entities/role';
import { FingerprintList } from './entities/Fingerprint';
import {
  CustomerAlarmCenterCreate,
  CustomerAlarmCenterEdit,
  CustomerAlarmCenterList,
} from './entities/customerAlarmCenter';

// languages
import en from '../../config/i18n/locales/en';
import nl from '../../config/i18n/locales/nl';
import de from '../../config/i18n/locales/de';
import fr from '../../config/i18n/locales/fr';
import {
  DutchFlag, EnglishFlag, FrenchFlag, GermanFlag,
} from '../../components/locales/Flags';
import { DomainlinkCreate, DomainlinkEdit, DomainlinkList } from './entities/domainLink';
import { XGACLayout } from './Layout';
import LoginPage from '../../components/login';
import { ZoneOverview } from '../../components/pages/zoneOverview';
import { queryClient } from '../../utils/queryClient';
import { Kiosk } from '../../components/kiosk';
import { AuditLogPage } from './pages/auditLogPage';
import { KioskDefaults } from './pages/kioskDefaults';

const theme: ThemeOptions & {
  sidebar: {
    width: number;
    closedWidth: number;
  };
} = {
  sidebar: {
    width: 260,
    closedWidth: 55,
  },
  ...createTheme({
    ...defaultTheme,
    components: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      RaMenuItem: {
        styleOverrides: {
          root: {
            fontSize: '11px',
          },
        },
      },
      MuiStack: {
        styleOverrides: {
          root: {
            marginTop: '10px',
            marginBottom: '10px',
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            width: '20px',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            boxShadow: 'none !important',
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            borderBottomWidth: 0,
          },
        },
      },
      MuiPaper: {
        defaultProps: {
          elevation: 0,
        },
      },
    },
    shape: {
      borderRadius: 0,
    },
    palette: {
      background: {
        default: '#FFF',
      },
      primary: {
        light: '#FFF',
        main: '#1A72BA',
        dark: '#008E8E',
        contrastText: '#FFF',
      },
      secondary: {
        light: '#FFF',
        main: '#1A72BA',
        dark: '#008E8E',
        contrastText: '#FFF',
      },
      error: {
        main: '#D0382A',
      },
      info: {
        main: '#FFFFFF',
        contrastText: '#000000',
      },
      warning: {
        main: '#EC812A',
      },
      success: {
        main: '#008E8E',
        light: '#008E8E',
        dark: '#008E8E',
        contrastText: '#FFFFFF',
      },
    },
  }),
};

const translations = {
  en: _.merge(raEn.default, en),
  nl: _.merge(raNl.default, nl),
  de: _.merge(formalGermanMessages, de),
  fr: _.merge(frenchMessages, fr),
};

export const i18nProvider = polyglotI18nProvider(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  (locale) => (translations[locale] ? translations[locale] : translations.en),
  resolveBrowserLocale(),
  [
    { locale: 'en', name: <EnglishFlag/> },
    { locale: 'nl', name: <DutchFlag/> },
    { locale: 'de', name: <GermanFlag/> },
    { locale: 'fr', name: <FrenchFlag/> },
  ],
  { allowMissing: true },
);

const XGACApp = () => {

  // Remove the permissions, so they are rebuild.
  useEffect(() => {

    const context = async () => {

      localStorage.removeItem('permissions');
      await refreshContext();
      if (authProvider?.getPermissions) {

        await authProvider.getPermissions({});

      }

    };
    context();

  }, []);

  return (
    <BrowserRouter>
      <UserSnap/>
      <title>X-Guard Management Dashboard</title>
      <Admin
        dataProvider={dataProvider}
        authProvider={authProvider}
        loginPage={LoginPage}
        layout={XGACLayout}
        lightTheme={theme}
        disableTelemetry={true}
        i18nProvider={i18nProvider}
        queryClient={queryClient}
        darkTheme={null}
      >
        {/* Mainapi */}
        <Resource name="alarms" create={AlarmCreate} list={AlarmList}/>
        <Resource name="problematic-alarms" list={OpenAlarmList}/>
        <Resource name="asset-groups" recordRepresentation="name" list={AssetGroupList} edit={AssetGroupEdit}
          create={AssetGroupCreate}/>
        <Resource name="assets/without-app" list={AssetList} edit={AssetEdit} create={AssetCreate}/>
        <Resource name="assets" recordRepresentation="name" edit={AssetEdit}/>
        <Resource name="app-users" recordRepresentation="name" list={AppUserList} edit={AppUserEdit} create={AppUserCreate}/>
        <Resource name="beacons" list={BeaconList} edit={BeaconEdit} create={BeaconCreate}/>
        <Resource name="contact-lists" list={ContactListList} edit={ContactListEdit} create={ContactListCreate}
          recordRepresentation="name"/>
        <Resource name="devices/without-app" list={DeviceList} edit={DeviceEdit} create={DeviceCreate}/>
        <Resource name="devices" edit={DeviceEdit}/>
        <Resource name="observation-triggers" list={ObservationTriggerList} edit={ObservationTriggerEdit}
          create={ObservationTriggerCreate}/>
        <Resource name="overlays" list={OverlayMapList}/>
        <Resource name="webhooks" list={WebhookList} edit={WebhookEdit} create={WebhookCreate}/>
        <Resource name="zones" recordRepresentation="name" list={ZoneList} edit={ZoneEdit} create={ZoneCreate}/>
        <Resource name="users/without-app" recordRepresentation="name" list={UserList} edit={UserEdit}
          create={UserCreate}/>
        <Resource name="users" recordRepresentation="name" list={UserList} edit={UserEdit} create={UserCreate}/>
        <Resource name="static-responders" recordRepresentation="name" list={StaticResponderList}
          edit={StaticResponderEdit} create={StaticResponderCreate}/>
        <Resource name="customers" recordRepresentation="name" list={CustomerList} edit={CustomerEdit} create={CustomerCreate}/>
        <Resource name="service-accounts" recordRepresentation="name" list={ServiceAccountList} edit={ServiceAccountEdit} create={ServiceAccountCreate}/>
        <Resource name="report-triggers" recordRepresentation="config.type" list={ReportTriggerList} edit={ReportTriggerEdit} create={ReportTriggerCreate}/>
        <Resource name="report-trigger-runs" list={ReportTriggerRunList}/>
        <Resource name="responsePartners" recordRepresentation="name"/>
        <Resource name="all-assets" recordRepresentation="name"/>
        <Resource name="roles" recordRepresentation="name" list={RoleList} edit={RoleEdit} create={RoleCreate}/>
        <Resource name="specials/kiosk-user" recordRepresentation="asset.name"/>
        <Resource name="fingerprints" list={FingerprintList}/>
        <Resource name="customer-alarm-center-relations" list={CustomerAlarmCenterList} edit={CustomerAlarmCenterEdit} create={CustomerAlarmCenterCreate}/>
        {/* Legacy device api */}
        <Resource name="flic-hubs" list={FlicHubList} edit={FlicHubEdit} create={FlicHubCreate}/>
        <Resource name="flic-buttons" list={FlicButtonList} edit={FlicButtonEdit} create={FlicButtonCreate} />
        <Resource name="domainlinks" list={DomainlinkList} edit={DomainlinkEdit} create={DomainlinkCreate}/>

        {/* Ats */}
        <Resource name="customer-links" list={CustomerLinkList} edit={CustomerLinkEdit} create={CustomerLinkCreate}/>
        <Resource name="process-templates" recordRepresentation="processName"
          list={ProcessTemplateList}
          edit={ProcessTemplateEdit}
        />
        <Resource name="task-templates" recordRepresentation="templateName"/>

        {/* qr codes */}
        <Resource name="templates" list={TemplateList} edit={TemplateEdit} create={TemplateCreate}/>
        <Resource name="lite-templates"/>
        <Resource name="codes"/>
        <Resource name="actions"/>
        <CustomRoutes>
          <Route path="/" element={<HomePage/>}/>
          <Route path="/kiosks/defaults" element={<KioskDefaults/>}/>
          <Route path="/live-map" element={<LiveMap/>}/>
          <Route path="/reports/unhealthy-beacons" element={<UnhealthyBeaconReport/>}/>
          <Route path="/reports/zone-overview" element={<ZoneOverview/>}/>
          <Route path="/zone-map" element={<SimpleZoneMap/>}/>
          <Route path="/login/hashed/:username/:key" element={<LoginPage/>}/>
          <Route path={'/kiosks'} element={<Kiosk/>}/>
          <Route path={'/audit-logs'} element={<AuditLogPage/>} />
        </CustomRoutes>
      </Admin>
    </BrowserRouter>
  );

};
export default XGACApp;
